<template>
  <div class="wrapper">
    <div class="sidebar">
      <div class="sidebar-header">
        <!--<img src="../assets/profile.jpg" alt="Image" />-->
      </div>
      <div class="sidebar-content">
        <nav class="navbar navbar-expand-md bg-dark navbar-dark">
          <a href="#" class="navbar-brand">Navigation</a>
          <button
            type="button"
            class="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarCollapse"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="nav navbar-nav">
              <li class="nav-item">
                <a class="nav-link" href="#header"
                  >Home<i class="fa fa-home"></i
                ></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#about"
                  >Über mich<i class="fa fa-address-card"></i
                ></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#experience"
                  >Erfahrung<i class="fa fa-star"></i
                ></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#contact"
                  >Kontakt<i class="fa fa-envelope"></i
                ></a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div class="sidebar-footer">
        <a href="https://twitter.com/philiplawall" target="_blank"
          ><i class="fab fa-twitter"></i
        ></a>
        <a href="https://www.linkedin.com/in/philip-lawall/" target="_blank"
          ><i class="fab fa-linkedin-in"></i
        ></a>
      </div>
    </div>
    <div class="content">
      <!-- Header Start -->
      <div class="header" id="header">
        <div class="content-inner">
          <p>Ich bin</p>
          <h1>Philip Lawall</h1>
          <h2></h2>
          <div class="typed-text">
            Crestron Programmierung, Extron Programmierung
          </div>
        </div>
      </div>
      <!-- Header End -->

      <!-- Large Button Start -->
      <div class="large-btn">
        <div class="content-inner">
          <!-- <a class="btn" href="#"><i class="fa fa-download"></i>Resume</a>
                        <a class="btn" href="#"><i class="fa fa-hands-helping"></i>Hire Me</a>-->
        </div>
      </div>
      <!-- Large Button End -->

      <!-- About Start -->
      <div class="about" id="about">
        <div class="content-inner">
          <div class="content-header">
            <h2>Über mich</h2>
          </div>
          <div class="row align-items-center">
            <!--<div class="col-md-6 col-lg-5">
                                <img src="../assets/about.jpg" alt="Image">
                            </div>-->
            <div class="col-md-6 col-lg-7">
              <p>
                Medientechnik Programmierer mit über 6 Jahren Berufserfahrung in
                den Bereichen Crestron Programmierung, Extron Programmierung, UI
                Design, System Konfiguration und Inbetriebnahme,
                Netzwerkkonfiguration Cisco und HP.
              </p>
              <!--<a class="btn" href="#">Hire Me</a>-->
            </div>
          </div>
          <!--<div class="row">
                            <div class="col-md-6">
                                <div class="skills">
                                    <div class="skill-name">
                                        <p>Design</p><p>85%</p>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div class="skill-name">
                                        <p>SEO</p><p>95%</p>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="skills">
                                    <div class="skill-name">
                                        <p>Development</p><p>90%</p>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div class="skill-name">
                                        <p>Marketing</p><p>85%</p>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>-->
        </div>
      </div>
      <!-- About End -->

      <!-- Education Start -->
      <div class="education" id="education">
        <div class="content-inner">
          <div class="content-header">
            <h2>Bildung</h2>
          </div>
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="edu-col">
                <span>2001 <i>bis</i> 2005</span>
                <h3>Grundschule</h3>
                <p>Grundschule Gensingen</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="edu-col">
                <span>2005 <i>bis</i> 2011</span>
                <h3>Weiterführende Schule</h3>
                <p>IGS Ingelheim</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="edu-col">
                <span>2011 <i>bis</i> 2013</span>
                <h3>Höhere Berufsfachschule</h3>
                <p>
                  Ausbildung zum Assistent für Informations- und
                  Kommunikationstechnik.
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="edu-col">
                <span>2013 <i>bis</i> 2016</span>
                <h3>Ausbildung</h3>
                <p>Ausbildung zum Fachinformatiker für Systemintegration.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Education Start -->

      <!-- Experience Start -->
      <div class="experience" id="experience">
        <div class="content-inner">
          <div class="content-header">
            <h2>Erfahrung</h2>
          </div>
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="exp-col">
                <span>2016 <i>bis</i> heute</span>
                <h3>BFE Studio und Medien Systeme GmbH</h3>
                <h4>Mainz</h4>
                <h5>Programmierer Medientechnik</h5>
                <p>
                  Lorem ipsum dolor sit amet elit suscipit orci. Donec molestie
                  velit id libero.
                </p>
              </div>
            </div>
            <!--<div class="col-md-6">
                                <div class="exp-col">
                                    <span>01-Jan-2020 <i>to</i> 31-Dec-2050</span>
                                    <h3>Soft Solution Ltd</h3>
                                    <h4>San Francisco, CA</h4>
                                    <h5>Web Developer</h5>
                                    <p>Lorem ipsum dolor sit amet elit suscipit orci. Donec molestie velit id libero.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="exp-col">
                                    <span>01-Jan-2020 <i>to</i> 31-Dec-2050</span>
                                    <h3>ABC Soft Ltd</h3>
                                    <h4>San Francisco, CA</h4>
                                    <h5>Web Designer</h5>
                                    <p>Lorem ipsum dolor sit amet elit suscipit orci. Donec molestie velit id libero.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="exp-col">
                                    <span>01-Jan-2020 <i>to</i> 31-Dec-2050</span>
                                    <h3>Soft Agency</h3>
                                    <h4>San Francisco, CA</h4>
                                    <h5>Graphic Designer</h5>
                                    <p>Lorem ipsum dolor sit amet elit suscipit orci. Donec molestie velit id libero.</p>
                                </div>
                            </div>-->
          </div>
        </div>
      </div>
      <!-- Experience Start -->

      <!-- Service Start -->
      <div class="service" id="service">
        <div class="content-inner">
          <div class="content-header">
            <h2>Fähigkeiten</h2>
          </div>
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="srv-col">
                <i class="fa fa-desktop"></i>
                <h3>Crestron Programmierung</h3>
                <p>
                  Lorem ipsum dolor sit amet elit suscipit orci. Donec molestie
                  velit id libero.
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="srv-col">
                <i class="fa fa-laptop"></i>
                <h3>Extron Programmierung</h3>
                <p>
                  Lorem ipsum dolor sit amet elit suscipit orci. Donec molestie
                  velit id libero.
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="srv-col">
                <i class="fa fa-search"></i>
                <h3>Technische Planung</h3>
                <p>
                  Lorem ipsum dolor sit amet elit suscipit orci. Donec molestie
                  velit id libero.
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="srv-col">
                <i class="fa fa-envelope-open-text"></i>
                <h3>Inbetriebnahme / Service</h3>
                <p>
                  Lorem ipsum dolor sit amet elit suscipit orci. Donec molestie
                  velit id libero.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Service Start -->

      <!-- Portfolio Start -->
      <!--<div class="portfolio" id="portfolio">
                    <div class="content-inner">
                        <div class="content-header">
                            <h2>Portfolio</h2>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <ul id="portfolio-flters">
                                    <li data-filter="*" class="filter-active">All</li>
                                    <li data-filter=".web-des">Design</li>
                                    <li data-filter=".web-dev">Development</li>
                                    <li data-filter=".dig-mar">Marketing</li>
                                </ul>
                            </div>
                        </div>
                        <div class="row portfolio-container">
                            <div class="col-lg-4 col-md-6 portfolio-item web-des">
                                <div class="portfolio-wrap">
                                    <figure>
                                        <img src="../assets/portfolio-1.jpg" class="img-fluid" alt="">
                                        <a href="../assets/portfolio-1.jpg" data-lightbox="portfolio" data-title="Project Name" class="link-preview" title="Preview"><i class="fa fa-eye"></i></a>
                                        <a href="#" class="link-details" title="More Details"><i class="fa fa-link"></i></a>
                                        <a class="portfolio-title" href="#">Project Name <span>Web Design</span></a>
                                    </figure>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 portfolio-item web-des">
                                <div class="portfolio-wrap">
                                    <figure>
                                        <img src="../assets/portfolio-2.jpg" class="img-fluid" alt="">
                                        <a href="../assets/portfolio-2.jpg" class="link-preview" data-lightbox="portfolio" data-title="Project Name" title="Preview"><i class="fa fa-eye"></i></a>
                                        <a href="#" class="link-details" title="More Details"><i class="fa fa-link"></i></a>
                                        <a class="portfolio-title" href="#">Project Name <span>Web Design</span></a>
                                    </figure>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 portfolio-item web-dev">
                                <div class="portfolio-wrap">
                                    <figure>
                                        <img src="../assets/portfolio-3.jpg" class="img-fluid" alt="">
                                        <a href="../assets/portfolio-3.jpg" class="link-preview" data-lightbox="portfolio" data-title="Project Name" title="Preview"><i class="fa fa-eye"></i></a>
                                        <a href="#" class="link-details" title="More Details"><i class="fa fa-link"></i></a>
                                        <a class="portfolio-title" href="#">Project Name <span>Web Development</span></a>
                                    </figure>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 portfolio-item web-dev">
                                <div class="portfolio-wrap">
                                    <figure>
                                        <img src="../assets/portfolio-4.jpg" class="img-fluid" alt="">
                                        <a href="../assets/portfolio-4.jpg" class="link-preview" data-lightbox="portfolio" data-title="Project Name" title="Preview"><i class="fa fa-eye"></i></a>
                                        <a href="#" class="link-details" title="More Details"><i class="fa fa-link"></i></a>
                                        <a class="portfolio-title" href="#">Project Name <span>Web Development</span></a>
                                    </figure>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 portfolio-item dig-mar">
                                <div class="portfolio-wrap">
                                    <figure>
                                        <img src="../assets/portfolio-5.jpg" class="img-fluid" alt="">
                                        <a href="../assets/portfolio-5.jpg" class="link-preview" data-lightbox="portfolio" data-title="Project Name" title="Preview"><i class="fa fa-eye"></i></a>
                                        <a href="#" class="link-details" title="More Details"><i class="fa fa-link"></i></a>
                                        <a class="portfolio-title" href="#">Project Name <span>Digital Marketing</span></a>
                                    </figure>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 portfolio-item dig-mar">
                                <div class="portfolio-wrap">
                                    <figure>
                                        <img src="../assets/portfolio-6.jpg" class="img-fluid" alt="">
                                        <a href="../assets/portfolio-6.jpg" class="link-preview" data-lightbox="portfolio" data-title="Project Name" title="Preview"><i class="fa fa-eye"></i></a>
                                        <a href="#" class="link-details" title="More Details"><i class="fa fa-link"></i></a>
                                        <a class="portfolio-title" href="#">Project Name <span>Digital Marketing</span></a>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
      <!-- Portfolio Start -->

      <!-- Review Start -->
      <!--<div class="review" id="review">
                    <div class="content-inner">
                        <div class="content-header">
                            <h2>Review</h2>
                        </div>
                        <div class="row align-items-center review-slider">
                            <div class="col-md-12">
                                <div class="review-slider-item">
                                    <div class="review-text">
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eu suscipit orci. Donec molestie velit id libero blandit, quis suscipit urna suscipit. Donec aliquet erat eu lacinia iaculis. Ut tempor tellus eu sem pharetra feugiat.
                                        </p>
                                    </div>
                                    <div class="review-img">
                                        <img src="../assets/review-1.jpg" alt="Image">
                                        <div class="review-name">
                                            <h3>Client Name</h3>
                                            <p>Profession</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="review-slider-item">
                                    <div class="review-text">
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eu suscipit orci. Donec molestie velit id libero blandit, quis suscipit urna suscipit. Donec aliquet erat eu lacinia iaculis. Ut tempor tellus eu sem pharetra feugiat.
                                        </p>
                                    </div>
                                    <div class="review-img">
                                        <img src="../assets/review-2.jpg" alt="Image">
                                        <div class="review-name">
                                            <h3>Client Name</h3>
                                            <p>Profession</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="review-slider-item">
                                    <div class="review-text">
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eu suscipit orci. Donec molestie velit id libero blandit, quis suscipit urna suscipit. Donec aliquet erat eu lacinia iaculis. Ut tempor tellus eu sem pharetra feugiat.
                                        </p>
                                    </div>
                                    <div class="review-img">
                                        <img src="../assets/review-3.jpg" alt="Image">
                                        <div class="review-name">
                                            <h3>Client Name</h3>
                                            <p>Profession</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
      <!-- Review End -->

      <!-- Contact Start -->
      <div class="contact" id="contact">
        <div class="content-inner">
          <div class="content-header">
            <h2>Kontakt</h2>
          </div>
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="contact-info">
                <p><i class="fa fa-user"></i>Philip Lawall</p>
                <p><i class="fa fa-tag"></i>Programmierung Medientechnik</p>
                <p>
                  <i class="fa fa-envelope"></i
                  ><a href="mailto:info@lawall.me">info@lawall.me</a>
                </p>
                <!--<p><i class="fa fa-phone"></i><a href="tel:+4917693286294">0176 / 93286294</a></p>-->
                <!--<p><i class="fa fa-map-marker"></i>Heddesheimer Weg 18, 55270 Essenheim</p>-->
                <div class="social">
                  <a
                    class="btn"
                    href="https://twitter.com/philiplawall"
                    target="_blank"
                    ><i class="fab fa-twitter"></i
                  ></a>
                  <a
                    class="btn"
                    href="https://www.linkedin.com/in/philip-lawall/"
                    target="_blank"
                    ><i class="fab fa-linkedin-in"></i
                  ></a>
                </div>
              </div>
            </div>
            <!--<div class="col-md-6">
                                <div class="form">
                                    <form>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <input type="text" class="form-control" placeholder="Your Name" />
                                            </div>
                                            <div class="form-group col-md-6">
                                                <input type="email" class="form-control" placeholder="Your Email" />
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Subject" />
                                        </div>
                                        <div class="form-group">
                                            <textarea class="form-control" rows="5" placeholder="Message"></textarea>
                                        </div>
                                        <div><button class="btn" type="submit">Send Message</button></div>
                                    </form>
                                </div>
                            </div>-->
          </div>
        </div>
      </div>
      <!-- Contact End -->

      <!-- Footer Start -->
      <div class="footer">
        <div class="content-inner">
          <div class="row align-items-center">
            <div class="col-md-6">
              <p>
                &copy; Copyright <a href="https://htmlcodex.com">HTML Codex</a>.
                All Rights Reserved
              </p>
            </div>
            <div class="col-md-6">
              <p>Powered by <a href="https://htmlcodex.com">HTML Codex</a></p>
            </div>
          </div>
        </div>
      </div>
      <!-- Footer Start -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
};
</script>

<style>
/**********************************/
/********** General CSS ***********/
/**********************************/
body {
  color: #666666;
  background: #f6d155;
  font-family: "Open Sans", sans-serif;
}

a {
  color: #222222;
  transition: all 0.3s;
}

a:hover,
a:active,
a:focus {
  color: #ff6f61;
  outline: none;
  text-decoration: none;
}

.btn:focus {
  box-shadow: none;
}

/**********************************/
/****** Layout with Sidebar *******/
/**********************************/
.wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1140px;
}

.wrapper .sidebar {
  position: relative;
  width: 100%;
  float: left;
  background: #222222;
}

.wrapper .content {
  position: relative;
  width: 100%;
  padding: 15px;
  float: left;
  background: #f6d155;
}

.wrapper .sidebar-header,
.wrapper .sidebar-footer {
  display: none;
}

.navbar {
  padding: 15px;
  background: #222222 !important;
}

.navbar-expand-md .navbar-nav .nav-item {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.navbar-expand-md .navbar-nav .nav-item:first-child {
  border-top: none;
}

.navbar-expand-md .navbar-nav .nav-link {
  color: #ffffff;
  padding: 5px 15px 7px 15px;
  transition: all 0.3s;
}

.navbar-expand-md .navbar-nav .nav-link i {
  color: #ff6f61;
  float: right;
  padding-top: 5px;
  transition: all 0.3s;
}

.navbar-expand-md .navbar-nav .nav-link:hover,
.navbar-expand-md .navbar-nav .nav-link.active {
  color: #ff6f61;
}

.navbar-expand-md .navbar-nav .nav-link:hover i,
.navbar-expand-md .navbar-nav .nav-link.active i {
  color: #ffffff;
}

.wrapper .sidebar,
.wrapper .content {
  -webkit-transition: margin 200ms ease-out;
  -moz-transition: margin 200ms ease-out;
  -o-transition: margin 200ms ease-out;
  transition: margin 200ms ease-out;
}

@media (min-width: 768px) {
  .wrapper .content {
    padding: 30px 0px 30px 30px;
  }

  /*.wrapper .content::before,
  .wrapper .content::after {
    position: fixed;
    content: "";
    width: 100%;
    height: 30px;
    background: #f6d155;
    z-index: 1;
  }*/

  .wrapper .content::before {
    top: 0;
  }

  .wrapper .content::after {
    bottom: 0;
  }

  .wrapper .sidebar {
    position: fixed;
    width: 300px;
    height: 100%;
    margin-left: -250px;
    float: left;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: gray;
    z-index: 2;
  }

  .wrapper .sidebar:hover {
    margin-left: 0px;
  }

  .wrapper .content {
    position: relative;
    width: calc(100% - 50px);
    margin-left: 50px;
    float: right;
  }

  .wrapper .sidebar:hover .sidebar-header {
    position: relative;
    display: block;
    width: 100%;
  }

  .wrapper .sidebar .sidebar-header img {
    width: 100%;
    height: auto;
  }

  .navbar-brand {
    display: none;
  }

  .navbar {
    padding: 15px 0;
    flex-direction: column;
  }

  .wrapper .sidebar:hover .navbar-expand-md .navbar-nav .nav-link {
    padding: 5px 30px 7px 30px;
  }

  .wrapper .sidebar::-webkit-scrollbar {
    width: 7px;
  }

  .wrapper .sidebar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  .wrapper .sidebar::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 7px;
  }

  .navbar-brand {
    display: none;
  }

  .sidebar .sidebar-footer {
    width: 300px;
    position: fixed;
    bottom: 0;
    padding: 15px;
    font-size: 0;
    text-align: center;
    background: #222222;
  }

  .sidebar .sidebar-footer a {
    display: inline-block;
    width: 40px;
    height: 40px;
    padding: 5px 0;
    margin: 0 15px 15px 0;
    text-align: center;
    font-size: 18px;
    color: #222222;
    background: #ff6f61;
  }

  .sidebar .sidebar-footer a:last-child {
    margin-right: 0;
  }

  .sidebar .sidebar-footer a:hover {
    color: #ff6f61;
    background: #ffffff;
  }

  .sidebar:hover .sidebar-footer {
    display: block;
  }
}

@media (min-width: 992px) {
  .wrapper .sidebar {
    margin: 0;
  }

  .wrapper .content {
    width: calc(100% - 300px);
    margin-left: 300px;
  }

  .sidebar .sidebar-header {
    position: relative;
    display: block;
    width: 100%;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding: 5px 30px 7px 30px;
  }

  .sidebar .sidebar-footer {
    display: block;
  }
}

@media (max-width: 1140px) {
  .wrapper .content {
    padding: 30px;
  }
}

@media (max-width: 576px) {
  .wrapper .content {
    padding: 15px;
  }
}

/**********************************/
/******** Back to Top CSS *********/
/**********************************/
.back-to-top {
  position: fixed;
  display: none;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 1;
  font-size: 30px;
  right: 30px;
  bottom: 30px;
  transition: background 0.5s;
  z-index: 11;
}

.back-to-top i {
  color: #222222;
}

.back-to-top i:hover {
  color: #ff6f61;
}

.back-to-top {
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
}

@-webkit-keyframes action {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}

@keyframes action {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}

/**********************************/
/*********** Header CSS ***********/
/**********************************/
.header {
  position: relative;
  background: #ffffff;
}

.header .content-inner {
  padding: 100px 30px;
  /*background: url(../../assets/profile.png) right bottom no-repeat;*/
  background-size: contain;
}

.header p {
  color: #ff6f61;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

.header h1 {
  color: #222222;
  font-size: 50px;
  font-weight: 700;
}

.header h2 {
  display: inline-block;
  margin: 0;
  height: 25px;
  font-size: 25px;
}

.header .typed-text {
  display: none;
}

.header .typed-cursor {
  font-size: 25px;
  font-weight: 300;
  color: #222222;
}

.large-btn .content-inner {
  font-size: 0;
  border-bottom: 30px solid #f6d155;
}

.large-btn .btn {
  position: relative;
  width: 50%;
  padding: 15px 0;
  color: #ff6f61;
  font-size: 20px;
  background: #222222;
  border-radius: 0;
}

.large-btn .btn:last-child {
  color: #222222;
  background: #ff6f61;
}

.large-btn .btn i {
  margin-right: 8px;
}

.large-btn .btn::after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}

.large-btn .btn:hover::after {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
}

/**********************************/
/******* Content Header CSS *******/
/**********************************/
.content-header {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 10px;
}

.content-header h2 {
  color: #222222;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 0;
}

.content-header::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  bottom: 0;
  left: 0;
  background: #ff6f61;
}

/**********************************/
/*********** About CSS ************/
/**********************************/
.about {
  position: relative;
  padding: 60px 30px;
  background: #ffffff;
  border-bottom: 30px solid #f6d155;
}

.about img {
  width: 100%;
  height: auto;
}

@media (max-width: 767.98px) {
  .about img {
    margin-bottom: 15px;
  }
}

.about .btn {
  border-radius: 0;
  color: #222222;
  background: #ff6f61;
  transition: all 0.3s;
}

.about .btn:hover {
  color: #ff6f61;
  background: #222222;
}

.about .skills {
  padding-top: 15px;
}

.about .skill-name {
  margin-top: 15px;
}

.about .skill-name p {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 400;
}

.about .skill-name p:last-child {
  float: right;
}

.about .progress {
  height: 5px;
  border: 1px solid #ff6f61;
  border-radius: 0;
  background: #ffffff;
}

.about .progress .progress-bar {
  width: 1px;
  background: #ff6f61;
  border-radius: 0;
  transition: 1s;
}

/**********************************/
/********* Education CSS **********/
/**********************************/
.education {
  position: relative;
  padding: 60px 30px;
  background: #ffffff;
  border-bottom: 30px solid #f6d155;
}

.education .edu-col {
  position: relative;
  width: 100%;
  padding: 20px 0 20px 20px;
  border-left: 1px solid #ff6f61;
  border-bottom: 1px solid #ff6f61;
}

.education .col-md-6:first-child .edu-col {
  padding-top: 0;
}

.education .col-md-6:last-child .edu-col {
  border-bottom: none;
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .education .col-md-6:nth-child(-n + 2) .edu-col {
    padding-top: 0;
  }

  .education .col-md-6:last-child .edu-col,
  .education .col-md-6:nth-last-child(2) .edu-col {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.education .edu-col span {
  position: relative;
  display: block;
  font-size: 13px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.education .edu-col span::before {
  position: absolute;
  content: "";
  width: 11px;
  height: 11px;
  background: #ff6f61;
  top: 5px;
  left: -26px;
  border-radius: 5px;
}

.education .edu-col span i {
  color: #ff6f61;
}

.education .edu-col h3 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;
}

.education .edu-col p {
  margin: 0;
}

/**********************************/
/********* Experience CSS *********/
/**********************************/
.experience {
  position: relative;
  padding: 60px 30px;
  background: #ffffff;
  border-bottom: 30px solid #f6d155;
}

.experience .exp-col {
  position: relative;
  width: 100%;
  padding: 20px 0 20px 20px;
  border-left: 1px solid #ff6f61;
  border-bottom: 1px solid #ff6f61;
}

.experience .col-md-6:first-child .exp-col {
  padding-top: 0;
}

.experience .col-md-6:last-child .exp-col {
  border-bottom: none;
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .experience .col-md-6:nth-child(-n + 2) .exp-col {
    padding-top: 0;
  }

  .experience .col-md-6:last-child .exp-col,
  .experience .col-md-6:nth-last-child(2) .exp-col {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.experience .exp-col span {
  position: relative;
  display: block;
  font-size: 13px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.experience .exp-col span::before {
  position: absolute;
  content: "";
  width: 11px;
  height: 11px;
  background: #ff6f61;
  top: 5px;
  left: -26px;
  border-radius: 5px;
}

.experience .exp-col span i {
  color: #ff6f61;
}

.experience .exp-col h3 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 5px;
}

.experience .exp-col h4 {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  margin-bottom: 5px;
}

.experience .exp-col h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.experience .exp-col p {
  margin: 0;
}

/**********************************/
/*********** Service CSS **********/
/**********************************/
.service {
  position: relative;
  padding: 60px 30px 30px 30px;
  background: #ffffff;
  border-bottom: 30px solid #f6d155;
}

.service .srv-col {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}

.service .srv-col i {
  font-size: 30px;
  color: #ff6f61;
  margin-bottom: 15px;
}

.service .srv-col h3 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 5px;
}

.experience .exp-col h4 {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  margin-bottom: 5px;
}

.experience .exp-col h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.experience .exp-col p {
  margin: 0;
}

/**********************************/
/********** Portfolio CSS *********/
/**********************************/
.portfolio {
  position: relative;
  padding: 60px 30px 30px 30px;
  background: #ffffff;
  border-bottom: 30px solid #f6d155;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: -15px 0 30px 0;
  list-style: none;
  font-size: 0;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  margin: 15px -1px 0 0;
  display: inline-block;
  height: 35px;
  padding: 10px;
  color: #666666;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  border: 1px solid #ff6f61;
  background: #ffffff;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: #ff6f61;
  color: #222222;
}

.portfolio .portfolio-item {
  position: relative;
  height: 250px;
  overflow: hidden;
}

.portfolio .portfolio-item figure {
  background: #ffffff;
  overflow: hidden;
  height: 220px;
  position: relative;
  border-radius: 0;
  margin: 0;
}

.portfolio .portfolio-item figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portfolio .portfolio-item figure:hover img {
  opacity: 0.3;
  transition: 0.3s;
}

.portfolio .portfolio-item figure .link-preview,
.portfolio .portfolio-item figure .link-details,
.portfolio .portfolio-item figure .portfolio-title {
  position: absolute;
  display: inline-block;
  opacity: 0;
  line-height: 1;
  text-align: center;
  width: 45px;
  height: 35px;
  background: #ff6f61;
  transition: 0.2s linear;
}

.portfolio .portfolio-item figure .link-preview i,
.portfolio .portfolio-item figure .link-details i {
  color: #222222;
  padding-top: 10px;
  font-size: 16px;
}

.portfolio .portfolio-item figure .link-preview:hover,
.portfolio .portfolio-item figure .link-details:hover {
  background: #222222;
}

.portfolio .portfolio-item figure .link-preview:hover i,
.portfolio .portfolio-item figure .link-details:hover i {
  color: #ff6f61;
}

.portfolio .portfolio-item figure .link-preview {
  left: 50%;
  top: calc(25% - 18px);
}

.portfolio .portfolio-item figure .link-details {
  right: 50%;
  top: calc(25% - 18px);
}

.portfolio .portfolio-item figure .portfolio-title {
  margin: 0;
  width: 100%;
  height: 50%;
  left: 0;
  right: 0;
  top: 100%;
  padding: 30px 10px;
  color: #222222;
  font-size: 16px;
  font-weight: 600;
  background: rgba(255, 111, 97, 0.5);
  border-radius: 0;
}

.portfolio .portfolio-item figure .portfolio-title:hover {
  text-decoration: none;
}

.portfolio .portfolio-item figure .portfolio-title span {
  display: block;
  margin-top: 10px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 300;
}

.portfolio .portfolio-item figure:hover .link-preview {
  opacity: 1;
  left: calc(50% - 61px);
}

.portfolio .portfolio-item figure:hover .link-details {
  opacity: 1;
  right: calc(50% - 61px);
}

.portfolio .portfolio-item figure:hover .portfolio-title {
  opacity: 1;
  top: 50%;
}

/**********************************/
/*********** Review CSS ***********/
/**********************************/
.review {
  position: relative;
  background: #ffffff;
  border-bottom: 30px solid #f6d155;
}

.review .content-inner {
  position: relative;
  padding: 60px 30px;
  background: url(../assets/quote.png) right bottom no-repeat;
}

.review .review-text p {
  font-size: 18px;
  font-style: italic;
}

.review .review-img {
  display: flex;
  align-items: center;
}

.review .review-img img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 15px;
}

.review .review-name h3 {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 5px;
}

.review .review-name p {
  font-size: 14px;
  margin-bottom: 0;
}

.review .review-text .ratting {
  margin-bottom: 5px;
}

.review .review-text .ratting i {
  color: #ff6f61;
  font-size: 14px;
}

.review .slick-slider:hover .slick-prev {
  left: 30px;
}

.review .slick-slider:hover .slick-next {
  right: 30px;
}

.review-slider .slick-prev,
.review-slider .slick-next {
  width: 35px;
  height: 35px;
  z-index: 1;
  opacity: 0;
  transition: 0.5s;
  background: rgba(255, 111, 97, 0.7);
}

.review-slider .slick-prev {
  left: 55px;
}

.review-slider .slick-next {
  right: 55px;
}

.review-slider.slick-slider:hover .slick-prev {
  left: 15px;
  opacity: 1;
}

.review-slider.slick-slider:hover .slick-next {
  right: 15px;
  opacity: 1;
}

.review-slider .slick-prev:hover,
.review-slider .slick-prev:focus,
.review-slider .slick-next:hover,
.review-slider .slick-next:focus {
  background: rgba(0, 0, 0, 0.7);
}

.review-slider .slick-prev:hover::before,
.review-slider .slick-prev:focus::before,
.review-slider .slick-next:hover::before,
.review-slider .slick-next:focus::before {
  color: #ff6f61;
}

.review-slider .slick-prev::before,
.review-slider .slick-next::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 18px;
  color: #ffffff;
}

.review-slider .slick-prev::before {
  content: "\f104";
}

.review-slider .slick-next::before {
  content: "\f105";
}

.review-slider .slick-dots {
  bottom: 15px;
}

.review-slider .slick-dots li button:before {
  color: #ffffff;
  font-size: 15px;
}

.review-slider .slick-dots li.slick-active button:before {
  color: #ff6f61;
}

.review-slider.slick-slider {
  margin-bottom: 0;
}

/**********************************/
/*********** Contact CSS ***********/
/**********************************/
.contact {
  position: relative;
  padding: 60px 30px 30px 30px;
  background: #ffffff;
}

.contact .contact-info {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}

.contact .contact-info p {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.contact .contact-info p i {
  width: 25px;
  color: #ff6f61;
}

.contact .contact-info p a {
  color: #666666;
}

.contact .contact-info p a:hover {
  color: #ff6f61;
  text-decoration: none;
}

.contact .social {
  font-size: 0;
}

.contact .social a {
  width: 35px;
  height: 35px;
  padding: 5px 0;
  color: #222222;
  background: #ff6f61;
  border-radius: 0;
  margin-top: 15px;
  margin-right: 15px;
  transition: all 0.3s;
}

.contact .social a:last-child {
  margin-right: 0;
}

.contact .social a:hover {
  color: #ff6f61;
  background: #222222;
}

.contact .form {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}

.contact .form .form-control {
  border-radius: 0;
}

.contact .form .form-control:focus {
  box-shadow: none;
  border-color: #ff6f61;
}

.contact .form .btn {
  color: #222222;
  background: #ff6f61;
  border-radius: 0;
  transition: all 0.3s;
}

.contact .form .btn:hover {
  color: #ff6f61;
  background: #222222;
}

/**********************************/
/*********** Footer CSS ***********/
/**********************************/
.footer {
  position: relative;
  width: 100%;
  padding: 30px 30px 0 30px;
  background: #f6d155;
}

.footer .col-md-6:last-child {
  text-align: right;
}

@media (max-width: 767.98px) {
  .footer .col-md-6,
  .footer .col-md-6:last-child {
    text-align: center;
  }
}

.footer p {
  color: #222222;
  margin: 0;
}

.footer a {
  color: #ff6f61;
  font-weight: 600;
}

.footer a:hover {
  color: #222222;
}
</style>
